// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artykuly-charakter-prawny-umowy-1-js": () => import("./../../../src/pages/artykuly/Charakter_prawny_umowy1.js" /* webpackChunkName: "component---src-pages-artykuly-charakter-prawny-umowy-1-js" */),
  "component---src-pages-artykuly-charakter-prawny-umowy-2-js": () => import("./../../../src/pages/artykuly/Charakter_prawny_umowy2.js" /* webpackChunkName: "component---src-pages-artykuly-charakter-prawny-umowy-2-js" */),
  "component---src-pages-artykuly-planowana-nowelizacja-ksh-js": () => import("./../../../src/pages/artykuly/Planowana_nowelizacja_KSH.js" /* webpackChunkName: "component---src-pages-artykuly-planowana-nowelizacja-ksh-js" */),
  "component---src-pages-artykuly-prawo-holdingowe-js": () => import("./../../../src/pages/artykuly/Prawo_holdingowe.js" /* webpackChunkName: "component---src-pages-artykuly-prawo-holdingowe-js" */),
  "component---src-pages-artykuly-przeciwdzialanie-zatorom-platniczym-js": () => import("./../../../src/pages/artykuly/Przeciwdzialanie_zatorom_platniczym.js" /* webpackChunkName: "component---src-pages-artykuly-przeciwdzialanie-zatorom-platniczym-js" */),
  "component---src-pages-artykuly-pzp-waloryzacje-js": () => import("./../../../src/pages/artykuly/PZP_waloryzacje.js" /* webpackChunkName: "component---src-pages-artykuly-pzp-waloryzacje-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-specjalizacje-fundacje-rodzinne-js": () => import("./../../../src/pages/specjalizacje/fundacje-rodzinne.js" /* webpackChunkName: "component---src-pages-specjalizacje-fundacje-rodzinne-js" */),
  "component---src-pages-specjalizacje-fuzje-i-przejecia-js": () => import("./../../../src/pages/specjalizacje/fuzje-i-przejecia.js" /* webpackChunkName: "component---src-pages-specjalizacje-fuzje-i-przejecia-js" */),
  "component---src-pages-specjalizacje-prawo-kolejowe-js": () => import("./../../../src/pages/specjalizacje/prawo-kolejowe.js" /* webpackChunkName: "component---src-pages-specjalizacje-prawo-kolejowe-js" */),
  "component---src-pages-specjalizacje-prawo-spolek-js": () => import("./../../../src/pages/specjalizacje/prawo-spolek.js" /* webpackChunkName: "component---src-pages-specjalizacje-prawo-spolek-js" */),
  "component---src-pages-specjalizacje-rynek-kapitalowy-js": () => import("./../../../src/pages/specjalizacje/rynek-kapitalowy.js" /* webpackChunkName: "component---src-pages-specjalizacje-rynek-kapitalowy-js" */),
  "component---src-pages-zespol-1-maciej-was-js": () => import("./../../../src/pages/zespol/1_maciej_was.js" /* webpackChunkName: "component---src-pages-zespol-1-maciej-was-js" */),
  "component---src-pages-zespol-10-marcin-kotlarz-js": () => import("./../../../src/pages/zespol/10_marcin_kotlarz.js" /* webpackChunkName: "component---src-pages-zespol-10-marcin-kotlarz-js" */),
  "component---src-pages-zespol-11-piotr-pakulski-js": () => import("./../../../src/pages/zespol/11_piotr_pakulski.js" /* webpackChunkName: "component---src-pages-zespol-11-piotr-pakulski-js" */),
  "component---src-pages-zespol-12-michal-wasowicz-js": () => import("./../../../src/pages/zespol/12_michal_wasowicz.js" /* webpackChunkName: "component---src-pages-zespol-12-michal-wasowicz-js" */),
  "component---src-pages-zespol-13-filip-mulik-js": () => import("./../../../src/pages/zespol/13_filip_mulik.js" /* webpackChunkName: "component---src-pages-zespol-13-filip-mulik-js" */),
  "component---src-pages-zespol-14-piotr-was-js": () => import("./../../../src/pages/zespol/14_piotr_was.js" /* webpackChunkName: "component---src-pages-zespol-14-piotr-was-js" */),
  "component---src-pages-zespol-16-empty-js": () => import("./../../../src/pages/zespol/16_empty.js" /* webpackChunkName: "component---src-pages-zespol-16-empty-js" */),
  "component---src-pages-zespol-17-zenon-kotlarz-js": () => import("./../../../src/pages/zespol/17_zenon_kotlarz.js" /* webpackChunkName: "component---src-pages-zespol-17-zenon-kotlarz-js" */),
  "component---src-pages-zespol-18-olga-slabonska-js": () => import("./../../../src/pages/zespol/18_olga_slabonska.js" /* webpackChunkName: "component---src-pages-zespol-18-olga-slabonska-js" */),
  "component---src-pages-zespol-19-barbara-andershon-jackiewicz-js": () => import("./../../../src/pages/zespol/19_barbara_andershon_jackiewicz.js" /* webpackChunkName: "component---src-pages-zespol-19-barbara-andershon-jackiewicz-js" */),
  "component---src-pages-zespol-2-daniel-krajewski-js": () => import("./../../../src/pages/zespol/2_daniel_krajewski.js" /* webpackChunkName: "component---src-pages-zespol-2-daniel-krajewski-js" */),
  "component---src-pages-zespol-20-wiktoria-krajnik-js": () => import("./../../../src/pages/zespol/20_wiktoria_krajnik.js" /* webpackChunkName: "component---src-pages-zespol-20-wiktoria-krajnik-js" */),
  "component---src-pages-zespol-23-magdalena-jaskot-js": () => import("./../../../src/pages/zespol/23_magdalena_jaskot.js" /* webpackChunkName: "component---src-pages-zespol-23-magdalena-jaskot-js" */),
  "component---src-pages-zespol-24-jacek-jaruchowski-js": () => import("./../../../src/pages/zespol/24_jacek_jaruchowski.js" /* webpackChunkName: "component---src-pages-zespol-24-jacek-jaruchowski-js" */),
  "component---src-pages-zespol-25-magdalena-szymko-js": () => import("./../../../src/pages/zespol/25_magdalena_szymko.js" /* webpackChunkName: "component---src-pages-zespol-25-magdalena-szymko-js" */),
  "component---src-pages-zespol-26-igor-juzwa-js": () => import("./../../../src/pages/zespol/26_igor_juzwa.js" /* webpackChunkName: "component---src-pages-zespol-26-igor-juzwa-js" */),
  "component---src-pages-zespol-27-martyna-bodych-js": () => import("./../../../src/pages/zespol/27_martyna_bodych.js" /* webpackChunkName: "component---src-pages-zespol-27-martyna-bodych-js" */),
  "component---src-pages-zespol-28-agnieszka-witkowska-js": () => import("./../../../src/pages/zespol/28_agnieszka_witkowska.js" /* webpackChunkName: "component---src-pages-zespol-28-agnieszka-witkowska-js" */),
  "component---src-pages-zespol-3-anna-szymanska-js": () => import("./../../../src/pages/zespol/3_anna_szymanska.js" /* webpackChunkName: "component---src-pages-zespol-3-anna-szymanska-js" */),
  "component---src-pages-zespol-4-magdalena-sliwa-wajda-js": () => import("./../../../src/pages/zespol/4_magdalena_sliwa_wajda.js" /* webpackChunkName: "component---src-pages-zespol-4-magdalena-sliwa-wajda-js" */),
  "component---src-pages-zespol-5-aleksander-hetko-js": () => import("./../../../src/pages/zespol/5_aleksander_hetko.js" /* webpackChunkName: "component---src-pages-zespol-5-aleksander-hetko-js" */),
  "component---src-pages-zespol-7-janusz-werczynski-js": () => import("./../../../src/pages/zespol/7_janusz_werczynski.js" /* webpackChunkName: "component---src-pages-zespol-7-janusz-werczynski-js" */),
  "component---src-pages-zespol-8-miroslaw-boruta-js": () => import("./../../../src/pages/zespol/8_miroslaw_boruta.js" /* webpackChunkName: "component---src-pages-zespol-8-miroslaw-boruta-js" */),
  "component---src-pages-zespol-9-dagmara-gabrysiak-js": () => import("./../../../src/pages/zespol/9_dagmara_gabrysiak.js" /* webpackChunkName: "component---src-pages-zespol-9-dagmara-gabrysiak-js" */)
}

